import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { getProgramInclusion, type BaseUser } from 'Users';

angular.module('TuitionAndRegistrationSection', []).factory('registrationWatcher', [
    '$injector',
    ($injector: ng.auto.IInjectorService) => {
        const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');

        return {
            watchForRegistration() {
                $rootScope.$watch(
                    () => getProgramInclusion($rootScope.currentUser as BaseUser),
                    programInclusion => {
                        // Once we see that the registration process has been completed, we no longer need
                        // to have completedStripeCheckoutSession set.
                        //
                        // In most cases, it would be fine to just leave it. The only place where it would cause
                        // a problem is if the user eventually gets backed out of registration. In that case, if
                        // completedStripeCheckoutSession was still set in local storage, the user would not see
                        // the registration page.
                        //
                        // This strategy leaves one very unlikely edge case:
                        // 1. A user completes a stripe checkout session and returns to the client
                        // 2. completedStripeCheckoutSession gets set
                        // 3. Before the programInclusion is pulled down in the ping, the user closes the client
                        // 4. At some point before the user ever opens this client again, the user gets backed out of registration
                        // 5. When the user re-opens the client, completedStripeCheckoutSession will still be set and the
                        //      user will not see the registration page.
                        if ($rootScope.currentUser && programInclusion) {
                            $rootScope.currentUser.completedStripeCheckoutSession = false;
                            // registeredForPreviousCohort gets set up the same way as
                            // completedStripeCheckoutSession and can also be removed once we have a programInclusion
                            $rootScope.currentUser.registeredForPreviousCohort = false;
                        }
                    },
                );
            },
        };
    },
]);
