import { useEffect, useState } from 'react';

export function useWaitFor<T>(callback: () => T | null | undefined, maxAttempts = 50, reset = false) {
    const [result, setResult] = useState<T | null>(null);
    const [attemptCount, setAttemptCount] = useState(0);

    useEffect(() => {
        if (reset) {
            // allow resetting the count externally
            setAttemptCount(0);
        }
    }, [reset]);

    useEffect(() => {
        if (result) return undefined;

        const timeoutId = setTimeout(() => {
            setResult(callback() || null);
            if (attemptCount < maxAttempts) {
                setAttemptCount(attemptCount + 1);
            } // This will force us to try again until we have a result
        }, 50);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [result, callback, attemptCount, maxAttempts]);

    return result;
}
