/**
 * This method uses Pythagoras' Theorem to calculate the distance between the center of two elements
 * in the DOM. In order to calculate the distance between two elements with this theorem, we
 * need to do the following:
 *  1. Calculate the vertical center of each element on-screen, giving us `a`
 *  2. Calculate the horizontal center of each element on-screen, giving us `b`
 *  3. Plug `a` and `b` into the formula `sqrt(a^2 + b^2)`, giving us `c` (the distance)
 *
 * @param {DOM element} x
 * @param {DOM element} y
 *
 * @return {number} the calcualted distance, in pixels, between `x` and `y`.
 */
export default function getDistanceBetweenElementCenters(x, y) {
    try {
        const xRect = x.getBoundingClientRect();
        const yRect = y.getBoundingClientRect();

        const xVerticalCenter = xRect.top + xRect.height / 2;
        const yVerticalCenter = yRect.top + yRect.height / 2;

        const xHorizontalCenter = xRect.left + xRect.width / 2;
        const yHorizontalCenter = yRect.left + yRect.width / 2;

        return Math.sqrt(
            Math.pow(xVerticalCenter - yVerticalCenter, 2) + Math.pow(xHorizontalCenter - yHorizontalCenter, 2),
        );
    } catch (e) {
        return undefined;
    }
}
