
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.greetingMessage = window.Smartly.locales.modules.en.back_royal.greetingMessage || {};
window.Smartly.locales.modules.en.back_royal.greetingMessage.greetingMessage = window.Smartly.locales.modules.en.back_royal.greetingMessage.greetingMessage || {};
window.Smartly.locales.modules.en.back_royal.greetingMessage.greetingMessage = {...window.Smartly.locales.modules.en.back_royal.greetingMessage.greetingMessage, ...{
                               
    "niceToMeetYou": "Nice to meet you, {name}!",
    "getToKnowYou": "I'm excited to get to know you and answer any academic questions you might have. Our Academic and Support teams are here to back me up, so don't hesitate to reach out to them if you need to.",
    "newAroundHere": "I'm also new around here, and my skills are continuing to grow, so check back frequently to see what I'm up to!",
    "doYouHaveAQuestion": "With all that in mind, do you have a question for me?",

                          
    "helloAgain": "Hello again!",
    "goodDay": "Good day.",
    "welcomeBack": "Welcome back!",
    "dayOfWeek": "Happy {dayOfWeek}, and welcome back!",
    "welcomeBackName": "Welcome back, {name}!",
    "hello": "Hello!",
    "hi": "Hi!",
    "greetingsName": "Greetings, {name}!",
    "hiThere": "Hi there!",
    "helloHuman": "Hello, human friend!",
    "morning": "Good morning!",
    "afternoon": "Good afternoon!",
    "evening": "Good evening!",
    "salutations": "Salutations!",
    "niceToSeeYou": "Nice to see you again!",
    "helloAndNiceToSeeYou": "Hello, and nice to see you again!",
    "niceToSeeYouName": "Nice to see you again, {name}!",
    "greetingsAndHappyDay": "Greetings and happy {dayOfWeek}!",
    "greatToHaveYou": "{name}, great to have you back!",
    "heyThere": "Hey there, {name}!",

                                 
    "doYouHave": "Do you have a question for me?",
    "whatCanI": "What can I assist you with?",
    "whatQuery": "What query brings you my way?",
    "whatTopic": "What topic would you like to explore?",
    "anyQuestions": "Any questions about coursework or your program that I can help with?",
    "whatSubjectMatter": "What subject matter has piqued your curiosity today?",
    "whatSubject": "What subject should we tackle today?",
    "whatTopicRequires": "What topic requires our attention?",
    "howMayI": "How may I support your academic journey today?",
    "anythingICanHelp": "Anything I can help you solve?",
    "whatQuestion": "What question do you bring?",
    "howCanI": "How can I help?",
    "whatInformation": "What information can I help you uncover today?",
    "areaOfInterest": "Is there a specific area of interest you'd like to explore?",
    "whatChallenges": "What challenges are you facing that I can provide assistance with?",
    "whichConcept": "Which concept or topic would you like to dive into together?",
    "whatsOnYourMind": "What's on your mind that I can help clarify or explain?",

                            
    "talkAboutNext": "What would you like to talk about next?",

                       
    "0": "Sunday",
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday",

                                          
    "myMission": "Hi, {name}! My mission is to help {institutionName} students succeed in their studies.",
    "haveWonderfulDay": "Have a wonderful day! 👋",
    "hopeYouWillApply": "I hope you'll apply to our next cohort! 👋",
    "completeInterview": "Don't forget to complete your admissions interview, and have a wonderful day! 👋",
    "hopeYouWillJoin": "I hope you'll join us! 👍",
    "congratulationsOnAcceptance": "Congratulations on your acceptance to {institutionName}, {name}! AI Advisor unlocks at class start on {startDate}.",
    "seeYouSoon": "See you soon! 👋"
}
}
    